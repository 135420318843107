import { useState } from "react";
import { useRecoilState } from "recoil";
import { toastState, loadingState } from "../utils/atom";
import { getAccessToken } from "../utils/common";
import axios from "axios";

const OpenAIPopup_V4 = ({ setRow, setTitle, onClose }) => {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);

    const [text, setText] = useState("");

    const executeOpenAI = async () => {
        // console.log("executeOpenAI", text);
        if (text == "") {
            setToast({
                isToast: true,
                message: "내용을 입력 하세요.",
            });
            return;
        }
        setLoading(true);
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/v4adm/openai`,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
            data: {
                text,
            },
        });
        console.log(data);

        setTitle(data.title);

        setRow(data);

        setLoading(false);

        onClose();
    };

    return (
        <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header py-3">
                        <button type="button" className="btn btn-sm btn-light py-0" onClick={executeOpenAI}>
                            적용
                        </button>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body p-0">
                        <textarea
                            style={{ height: "400px" }}
                            className="form-control form-control-sm border-0"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenAIPopup_V4;
