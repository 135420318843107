import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getAccessToken, replaceAll } from "../utils/common";
import { toastState, loadingState } from "../utils/atom";
import axios from "axios";
import KyhImageDropUploader from "../components/KyhImageDropUploader";
import KyhSingleFileUploader from "../components/KyhSingleFileUploader";
import RecipeStep_V4 from "./RecipeStep_V4";
import RecipeIngredients_V4 from "./RecipeIngredients_V4";
import OpenAIPopup_V4 from "./OpenAIPopup_V4";
import JsonWritePopup_V4 from "./JsonWritePopup_V4";

const RecipeDetail_V4 = ({ modalClose, idx }) => {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);
    const [categorys, setCategorys] = useState([]);
    const [popup, setPopup] = useState(false);
    const [popup2, setPopup2] = useState(false);

    const [title, setTitle] = useState("");
    const [filename0, setFilename0] = useState("");
    const [category, setCategory] = useState("");
    const [tags, setTags] = useState("");
    const [writer, setWriter] = useState("최고의요리비결");
    const [source, setSource] = useState("");

    const defaultStepRow = {
        title: "",
        instruction: "",
        tips: "",
        filename0: "",
        filename1: "",
        filename2: "",
        filename3: "",
        filename4: "",
        filename5: "",
        filename6: "",
        filename7: "",
        filename8: "",
        filename9: "",
    };
    const defaultIngredientRow = { item: "", capacity: "" };

    const [row, setRow] = useState({
        title: "",
        soge: "\n2인분",
        ingredients: {
            main: [defaultIngredientRow],
            seasoning: [defaultIngredientRow],
            etc: [defaultIngredientRow],
        },
        steps: [defaultStepRow],
    });

    useEffect(() => {
        (async () => {
            if (idx !== "add") {
                await fetch();
            }
            await getCategorys();
        })();
    }, [idx]);

    async function fetch() {
        const url = `${process.env.REACT_APP_HOST}/v4adm/recipe/${idx}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (data) {
            setTitle(data.title);
            setFilename0(data.filename0);
            setCategory(data.category);
            setTags(data.tags);
            setWriter(data.writer);
            setSource(data.source);

            try {
                const json = JSON.parse(data.json_data);
                console.log(json);
                for (const o of json.steps) {
                    delete o.step_number;
                    // for (var i = 0; i <= 9; i++) {
                    //     if (!o[`filename${i}`]) {
                    //         o[`filename${i}`] = "";
                    //     }
                    // }
                }
                setRow(json);
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function getCategorys() {
        const url = `${process.env.REACT_APP_HOST}/v4adm/categorys`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (data) {
            setCategorys(data);
        }
    }

    const getImageWidth = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                resolve(img.width); // 이미지의 가로 크기를 반환
            };

            img.onerror = (error) => {
                reject(error); // 이미지 로드에 실패하면 에러 반환
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // created, modified 제거!
        delete row.created;
        delete row.modified;

        const frm = {};

        if (idx) {
            if (idx !== "add") {
                frm.idx = idx;
            }
        }

        // filename0 가로 크키 구하기!
        if (filename0) {
            frm.width = await getImageWidth(filename0);
        } else {
            frm.width = 0;
        }
        frm.table = "V4_RECIPE_tbl";
        frm.title = title;
        frm.filename0 = filename0;
        frm.category = category;
        frm.tags = tags;
        frm.writer = writer;
        frm.source = source;
        console.log("frm", frm);
        const json_str = JSON.stringify(row);

        // 임시로 json_data 저장
        await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: {
                table: "V4_RCP_TEMP_tbl",
                idx: "1",
                json_data: json_str,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        //

        frm.json_data = json_str;

        console.log("frm", frm);

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: frm,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        setLoading(false);

        setToast({
            isToast: true,
            message: "저장 되었습니다.",
        });

        modalClose(true);
    };

    function setJsonData() {
        const tmp = prompt("", "");
        if (tmp === null || tmp.trim() === "") {
            return;
        }
        const newRow = JSON.parse(tmp);
        setRow(newRow);
    }

    function addRow(i, direction) {
        const steps = [...row.steps];
        if (direction === "up") {
            steps.splice(i, 0, defaultStepRow);
        } else {
            steps.splice(i + 1, 0, defaultStepRow);
        }
        setTimeout(() => {
            setRow({ ...row, steps });
        }, 300);
    }

    function removeRow(i) {
        const steps = [...row.steps];
        if (i === 0 && steps.length === 1) {
            window.alert("첫번째 행은 삭제할 수 없습니다.");
            return;
        }

        steps.splice(i, 1);
        setRow({ ...row, steps });
    }

    return (
        <div className="position-absolute top-0 start-0 bg-light w-100" style={{ zIndex: "999", display: "block" }}>
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between border-bottom py-3 mb-2 bg-white">
                    <div>
                        <button
                            type="submit"
                            className={`btn btn-${idx === "add" ? "primary" : "success"} btn-sm ms-2`}
                        >
                            {idx === "add" ? "Add" : "Modify"}
                        </button>
                    </div>
                    <button type="button" className="btn-close me-1" onClick={() => modalClose(false)}></button>
                </div>
                <div className="d-flex flex-row">
                    <button type="button" className="btn btn-sm btn-link" onClick={() => setPopup(true)}>
                        OpenAI
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => setPopup2(true)}>
                        json
                    </button>
                </div>

                <input type="hidden" name="idx" readOnly value={idx} />

                <div className="bg-white p-2 mt-2 mb-2 border-top border-bottom">
                    <div className="d-flex flex-row">
                        <div style={{ width: "100px" }}>
                            <div style={{ height: "100px" }}>
                                <KyhImageDropUploader filename={filename0} onResult={(url) => setFilename0(url)} />
                            </div>
                            <input
                                type="text"
                                name="filename0"
                                className="form-control form-control-sm mt-1 mb-1"
                                value={filename0}
                                onChange={(e) => setFilename0(e.target.value)}
                            />
                            <KyhSingleFileUploader onResult={(url) => setFilename0(url)} />
                        </div>

                        <div className="d-flex flex-column flex-fill ms-1">
                            <input
                                type="text"
                                name="title"
                                className="form-control form-control-sm"
                                placeholder="제목"
                                value={title}
                                onChange={(e) => {
                                    setRow({ ...row, title: e.target.value });
                                    setTitle(e.target.value);
                                }}
                                required
                            />

                            <textarea
                                name="soge"
                                className="form-control form-control-sm mt-1"
                                placeholder="소개글"
                                style={{ height: "100px" }}
                                value={row.soge}
                                onChange={(e) => setRow({ ...row, soge: e.target.value })}
                                required
                            />

                            <input
                                type="text"
                                name="servings"
                                className="form-control form-control-sm mt-1"
                                placeholder="인분"
                                value={row.servings}
                                onChange={(e) => setRow({ ...row, servings: e.target.value })}
                            />

                            <input
                                list="categorys_option"
                                type="text"
                                name="category"
                                className="form-control form-control-sm mt-1"
                                placeholder="카테고리"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            />

                            <datalist id="categorys_option">
                                {categorys.map((category, i) => (
                                    <option key={i} value={category} />
                                ))}
                            </datalist>

                            <input
                                type="text"
                                name="tags"
                                className="form-control form-control-sm mt-1"
                                placeholder="태그"
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                                onBlur={() => {
                                    const tmp = tags;
                                    const newTag = replaceAll(tmp, " ", "");
                                    setTags(newTag);
                                }}
                            />

                            <input
                                type="text"
                                name="writer"
                                className="form-control form-control-sm mt-1"
                                placeholder="작성자"
                                value={writer}
                                onChange={(e) => setWriter(e.target.value)}
                            />

                            <input
                                type="text"
                                name="source"
                                className="form-control form-control-sm mt-1"
                                placeholder="출처링크"
                                value={source}
                                onChange={(e) => setSource(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="bg-white p-2 mt-2 mb-2 border-top border-bottom">
                    <div className="fw-bold">재료</div>
                    <RecipeIngredients_V4 gbn="main" row={row} setRow={setRow} />

                    <div className="fw-bold mt-4">양념</div>
                    <RecipeIngredients_V4 gbn="seasoning" row={row} setRow={setRow} />

                    <div className="fw-bold mt-4">기타</div>
                    <RecipeIngredients_V4 gbn="etc" row={row} setRow={setRow} />
                </div>

                {row.steps.map((step, i) => (
                    <div key={`step_${i}`} className="mb-4 bg-white border-top border-bottom">
                        <div className="d-flex flex-row justify-content-between">
                            <button
                                id={`step${i + 1}`}
                                type="button"
                                className="btn btn-link text-danger"
                                style={{ fontSize: "10px" }}
                                onClick={() => addRow(i, "up")}
                            >
                                <i className="bi bi-arrow-up"></i>위에 행추가
                            </button>
                            <button type="button" className="btn btn-link text-danger" onClick={() => removeRow(i)}>
                                <i className="bi bi-dash-circle-fill"></i>
                            </button>
                        </div>

                        <RecipeStep_V4 step={step} i={i} row={row} setRow={setRow} />

                        <button
                            type="button"
                            className="btn btn-link ps-0"
                            style={{ fontSize: "10px" }}
                            onClick={() => addRow(i, "down")}
                        >
                            <i className="bi bi-arrow-down"></i>아래에 행추가
                        </button>
                    </div>
                ))}
            </form>

            {popup && <OpenAIPopup_V4 setRow={setRow} setTitle={setTitle} onClose={() => setPopup(false)} />}
            {popup2 && (
                <JsonWritePopup_V4
                    onResult={(json) => {
                        setPopup2(false);
                        if (json != "") {
                            // 파일 url 추출
                            const oldFileArr = [];
                            for (const o of row.steps) {
                                const arr = [];
                                for (var j = 0; j <= 9; j++) {
                                    if (o[`filename${j}`]) {
                                        arr.push(o[`filename${j}`]);
                                    }
                                }
                                oldFileArr.push(arr);
                            }

                            try {
                                const tmp = JSON.parse(json);

                                // tmp.steps 안에 oldFileArr의 파일명을 filename0 ~ filename9로 넣기
                                tmp.steps.forEach((step, index) => {
                                    const fileArr = oldFileArr[index] || [];
                                    fileArr.forEach((filename, idx) => {
                                        if (idx <= 9) {
                                            step[`filename${idx}`] = filename;
                                        }
                                    });
                                });

                                setTitle(tmp.title);
                                setRow(tmp);
                            } catch (e) {}
                        }
                    }}
                />
            )}
        </div>
    );
};

export default RecipeDetail_V4;
