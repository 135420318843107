import { useEffect, useRef, useState } from "react";

const RecipeIngredients_V4 = ({ gbn, row, setRow }) => {
    const [text, setText] = useState("");
    const textareaRef = useRef(null);

    useEffect(() => {
        try {
            const data = row.ingredients[gbn];
            var txt = "";

            var cnt = 0;
            for (const o of data) {
                txt += `${o.item.replace(/\s+/g, "")} ${o.capacity.replace(/\s+/g, "")}\n`;
                cnt++;
            }
            txt = txt.trim(); // trim()을 사용하여 마지막 \n을 제거
            setText(txt);

            if (textareaRef.current) {
                textareaRef.current.style.height = `${cnt * 30}px`;
            }
        } catch (e) {}
    }, [row.ingredients]);

    useEffect(() => {
        // console.log(text);
        const json = convertToItemCapacity(text);
        for (const o of json) {
            // console.log(o.capacity);
            if (!o.capacity) {
                textareaRef.current.style.color = "red";
            } else {
                textareaRef.current.style.color = "black";
            }
        }

        const ingredients = row.ingredients;
        ingredients[gbn] = json;
        setRow({ ...row, ingredients });
    }, [text]);

    const convertToItemCapacity = (text) => {
        const lines = text.split("\n"); // 각 줄을 분리
        const result = lines.map((line) => {
            const [item, capacity] = line.trim().split(/(?<=^\S+)\s+/); // 첫 번째 공백을 기준으로 분리
            return { item, capacity };
        });
        return result;
    };

    return (
        <div>
            <textarea
                ref={textareaRef}
                className="form-control form-control-sm"
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
        </div>
    );
};

export default RecipeIngredients_V4;
